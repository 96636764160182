import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)" >
<path d="M12965 18894 c-33 -8 -120 -19 -193 -23 -92 -6 -140 -14 -157 -25
-14 -9 -62 -19 -118 -24 -59 -6 -103 -15 -118 -25 -13 -10 -43 -17 -69 -17
-27 0 -64 -10 -95 -25 -27 -13 -64 -25 -80 -25 -17 0 -40 -7 -52 -15 -12 -8
-45 -20 -74 -25 -29 -6 -60 -17 -70 -26 -9 -8 -34 -18 -55 -21 -22 -3 -50 -14
-63 -25 -13 -10 -32 -18 -41 -18 -10 0 -53 -18 -95 -39 -43 -22 -90 -43 -105
-46 -15 -3 -42 -17 -61 -31 -18 -13 -38 -24 -45 -24 -6 0 -17 -7 -24 -15 -7
-8 -21 -15 -31 -15 -10 0 -30 -11 -45 -25 -15 -14 -35 -25 -45 -25 -10 0 -30
-11 -45 -25 -15 -14 -34 -25 -42 -25 -8 0 -35 -18 -61 -39 -26 -22 -59 -43
-72 -46 -14 -3 -43 -23 -65 -44 -21 -20 -60 -49 -86 -64 -26 -15 -66 -47 -90
-71 -24 -24 -74 -65 -113 -91 -59 -40 -121 -92 -169 -142 -6 -6 -67 -69 -135
-139 -67 -70 -135 -145 -150 -167 -14 -22 -30 -47 -36 -54 -5 -8 -34 -40 -64
-71 -29 -32 -60 -71 -67 -89 -8 -17 -30 -49 -49 -70 -20 -21 -43 -51 -51 -68
-25 -52 -41 -75 -63 -96 -11 -11 -21 -28 -21 -39 0 -10 -4 -21 -9 -25 -15 -9
-68 -91 -76 -117 -4 -12 -16 -31 -26 -41 -11 -10 -19 -29 -19 -42 0 -14 -9
-30 -20 -37 -12 -8 -20 -24 -20 -40 0 -18 -10 -36 -25 -50 -15 -13 -25 -32
-25 -48 0 -15 -9 -35 -19 -44 -10 -10 -22 -34 -26 -54 -4 -20 -13 -45 -22 -54
-8 -9 -17 -35 -20 -57 -3 -22 -14 -48 -24 -57 -11 -9 -19 -28 -19 -41 0 -13
-11 -45 -25 -71 -14 -26 -25 -63 -25 -82 0 -19 -9 -48 -20 -65 -11 -17 -20
-47 -20 -70 0 -22 -12 -62 -25 -90 -16 -32 -25 -67 -25 -98 0 -26 -8 -62 -17
-80 -12 -22 -19 -66 -23 -142 -5 -79 -12 -120 -25 -145 -17 -31 -19 -60 -19
-250 -1 -118 2 -223 6 -232 7 -17 29 -18 261 -18 183 0 256 3 265 12 8 8 12
67 12 199 0 165 2 192 20 230 14 33 20 66 20 129 0 66 5 94 20 123 11 21 23
66 27 100 3 34 13 73 21 86 9 12 19 48 23 78 4 30 14 62 22 71 9 10 19 40 23
67 4 28 15 61 25 74 11 13 19 33 19 44 0 11 8 34 19 51 10 17 21 47 25 66 3
20 15 43 26 53 11 9 20 27 20 40 0 13 9 32 19 41 10 10 22 32 25 49 3 18 15
40 26 50 11 10 20 29 20 46 0 16 6 31 14 34 8 3 31 35 50 70 20 36 43 71 51
77 8 7 15 18 15 25 0 7 17 35 38 62 21 26 53 74 70 105 18 31 49 71 70 89 20
18 42 40 47 50 44 74 358 409 383 409 12 0 74 49 146 116 33 30 73 61 91 68
17 7 48 30 69 50 22 20 49 39 62 42 12 3 30 14 40 25 9 10 23 19 30 19 7 0 18
9 24 20 6 12 21 20 35 20 13 0 32 8 42 18 10 11 32 26 48 34 37 18 107 58 120
68 6 4 23 10 39 14 16 4 41 16 54 27 14 10 32 19 41 19 9 0 30 9 46 20 17 11
37 20 45 20 8 0 38 12 65 25 28 14 61 25 75 25 14 0 39 9 55 20 17 11 41 20
55 20 14 0 44 9 65 21 22 11 63 23 90 26 28 3 64 14 81 24 22 13 51 19 95 19
46 0 75 6 103 21 25 13 52 19 79 17 l42 -3 3 -189 c2 -104 1 -199 -2 -211 -5
-20 -14 -23 -76 -27 -45 -4 -77 -11 -89 -22 -10 -9 -44 -19 -82 -22 -35 -4
-78 -15 -95 -25 -17 -11 -47 -19 -66 -19 -21 0 -46 -9 -61 -21 -15 -11 -44
-23 -64 -26 -21 -3 -49 -14 -62 -25 -13 -10 -38 -18 -56 -18 -19 0 -39 -8 -49
-19 -10 -11 -32 -23 -50 -27 -17 -3 -45 -15 -62 -25 -17 -10 -39 -19 -47 -19
-9 0 -21 -8 -26 -19 -6 -10 -26 -22 -46 -25 -19 -4 -35 -11 -35 -16 0 -9 -30
-28 -116 -73 -19 -9 -34 -21 -34 -26 0 -5 -9 -13 -21 -16 -24 -8 -106 -62
-111 -73 -2 -4 -29 -25 -61 -46 -122 -80 -472 -432 -551 -554 -14 -20 -41 -54
-60 -75 -20 -21 -36 -45 -36 -53 0 -9 -18 -36 -39 -62 -22 -26 -43 -59 -46
-74 -4 -14 -15 -34 -26 -44 -10 -9 -19 -21 -19 -26 0 -5 -20 -44 -45 -87 -24
-43 -47 -90 -50 -104 -4 -15 -13 -31 -21 -38 -7 -6 -16 -27 -20 -48 -4 -20
-15 -47 -25 -60 -11 -13 -19 -33 -19 -45 0 -12 -9 -38 -20 -59 -11 -20 -20
-47 -20 -59 0 -13 -11 -41 -25 -63 -16 -26 -24 -55 -25 -84 0 -24 -8 -57 -19
-75 -12 -20 -20 -59 -24 -109 -4 -51 -12 -88 -23 -105 -15 -22 -20 -58 -25
-192 -4 -91 -12 -183 -18 -205 -17 -66 -14 -90 14 -103 38 -17 1456 -17 1473
0 7 7 12 27 12 45 0 18 9 56 20 85 13 35 20 77 20 123 0 53 5 77 20 100 11 16
22 50 26 75 4 25 15 55 24 67 10 13 21 40 25 60 4 20 15 48 26 61 10 14 19 35
19 47 0 12 18 42 40 66 22 24 40 52 40 62 0 15 126 155 208 231 19 17 52 50
75 74 22 23 64 56 92 72 28 16 60 39 70 50 10 11 28 20 40 20 12 0 30 9 39 19
10 10 32 22 51 25 18 4 38 13 45 21 6 7 26 17 43 20 18 4 46 16 63 26 20 12
50 19 81 19 37 0 58 6 79 23 26 20 41 22 164 25 74 2 139 7 144 12 12 12 17
490 5 512 -8 16 -27 17 -189 14 -150 -3 -183 -6 -206 -20 -15 -10 -57 -21 -99
-25 -44 -4 -81 -13 -92 -23 -10 -8 -39 -19 -64 -22 -27 -4 -54 -15 -64 -26
-10 -11 -31 -20 -47 -20 -16 0 -36 -9 -45 -20 -10 -11 -29 -20 -44 -20 -14 0
-34 -9 -44 -20 -10 -11 -29 -20 -42 -20 -13 0 -33 -11 -45 -25 -12 -14 -30
-25 -41 -25 -10 0 -39 -18 -65 -39 -26 -22 -57 -44 -69 -48 -13 -5 -53 -36
-90 -70 -38 -34 -94 -85 -126 -113 -33 -28 -79 -77 -105 -108 -25 -31 -61 -72
-80 -90 -40 -38 -92 -111 -92 -129 0 -7 -8 -17 -18 -22 -9 -5 -32 -38 -49 -74
-18 -36 -40 -67 -48 -71 -9 -3 -15 -18 -15 -36 0 -18 -8 -37 -20 -47 -11 -10
-23 -33 -26 -52 -4 -19 -17 -49 -29 -67 -15 -21 -21 -42 -17 -59 3 -17 -2 -33
-15 -48 -12 -12 -23 -44 -27 -71 -3 -28 -12 -53 -19 -58 -21 -13 -391 -10
-405 4 -20 20 -15 102 8 128 13 15 20 37 20 65 0 28 7 51 21 69 12 15 24 47
27 71 2 25 13 51 23 61 11 9 19 27 19 39 0 12 9 34 20 48 11 14 20 28 20 31 0
13 71 155 79 161 5 3 12 18 16 34 3 16 13 35 20 41 8 6 28 36 44 65 17 30 40
59 51 65 11 6 20 16 20 21 0 6 11 24 25 41 14 16 25 36 25 43 0 33 414 425
448 425 5 0 26 18 47 41 22 22 58 48 80 58 22 10 57 32 79 50 21 17 44 31 51
31 7 0 23 8 36 19 13 10 35 22 49 26 14 4 36 16 49 26 13 11 34 19 46 19 12 0
30 8 39 19 9 10 35 21 56 25 22 3 55 15 72 26 18 11 47 20 64 20 17 0 42 8 55
18 13 11 49 22 79 25 30 4 73 16 95 27 27 14 59 20 105 20 43 0 80 7 109 20
36 17 65 20 180 20 75 0 141 4 148 8 9 6 13 179 13 750 1 408 -1 746 -5 752
-10 15 -250 11 -320 -6z"/>
<path d="M13787 17875 c-15 -14 -17 -44 -17 -254 0 -174 3 -241 13 -253 18
-24 131 -68 173 -68 21 0 46 -8 61 -20 15 -11 41 -20 60 -20 19 0 46 -9 59
-19 14 -11 44 -22 67 -26 23 -4 51 -15 62 -25 11 -10 31 -21 45 -25 14 -4 32
-13 40 -20 8 -7 30 -16 47 -20 18 -3 36 -12 40 -20 5 -7 35 -26 68 -43 33 -16
69 -39 79 -51 11 -12 25 -21 32 -21 7 0 40 -21 72 -47 33 -27 87 -67 119 -91
71 -51 241 -215 303 -292 24 -30 49 -59 55 -65 7 -5 19 -23 28 -40 8 -16 31
-46 51 -65 19 -19 36 -42 36 -52 0 -9 20 -41 45 -71 25 -31 45 -64 45 -75 0
-10 11 -29 25 -42 14 -13 25 -33 25 -45 0 -12 9 -30 20 -40 11 -10 20 -29 20
-43 0 -13 11 -37 25 -54 14 -16 25 -39 25 -51 0 -12 9 -35 20 -52 11 -16 20
-39 20 -50 0 -11 9 -39 20 -63 10 -23 22 -61 25 -85 4 -23 14 -50 22 -59 10
-11 19 -49 23 -101 5 -46 15 -98 24 -115 14 -27 16 -82 16 -399 1 -344 -1
-372 -20 -432 -11 -35 -20 -89 -20 -119 0 -42 -5 -61 -20 -77 -13 -14 -22 -42
-26 -83 -5 -41 -13 -67 -25 -78 -11 -10 -19 -31 -19 -49 0 -19 -8 -39 -19 -49
-11 -10 -21 -37 -25 -65 -4 -29 -14 -55 -26 -66 -11 -10 -20 -26 -20 -35 0
-10 -8 -28 -19 -41 -10 -13 -22 -35 -26 -47 -4 -13 -21 -44 -38 -69 -29 -41
-39 -57 -56 -89 -3 -7 -23 -36 -43 -66 -21 -30 -38 -60 -38 -65 0 -6 -29 -40
-65 -76 -36 -36 -65 -71 -65 -77 0 -13 -258 -270 -271 -270 -5 0 -29 -20 -52
-44 -24 -24 -70 -58 -102 -77 -33 -18 -62 -36 -65 -40 -9 -13 -77 -57 -98 -63
-10 -4 -27 -15 -37 -26 -10 -11 -26 -20 -35 -20 -9 0 -31 -11 -50 -25 -19 -14
-41 -25 -51 -25 -9 0 -22 -6 -28 -14 -6 -7 -31 -19 -56 -26 -25 -7 -49 -18
-55 -25 -6 -8 -30 -17 -53 -20 -23 -4 -49 -14 -58 -21 -9 -8 -42 -19 -74 -24
-32 -5 -65 -16 -74 -23 -9 -8 -45 -17 -81 -19 -39 -3 -81 -14 -105 -27 -33
-17 -57 -21 -140 -21 -70 0 -121 -6 -170 -20 -87 -25 -240 -27 -300 -4 -25 10
-94 18 -185 23 -116 5 -150 10 -173 25 -16 11 -53 20 -95 23 -47 4 -72 11 -85
24 -12 13 -32 19 -63 19 -30 0 -56 7 -74 20 -15 10 -46 22 -68 26 -23 4 -47
13 -53 20 -6 7 -26 16 -44 20 -19 3 -41 15 -51 25 -9 10 -27 19 -39 19 -12 0
-30 9 -40 20 -10 11 -33 22 -51 26 -19 3 -36 14 -39 24 -3 10 -17 21 -30 24
-13 3 -45 21 -70 39 -26 17 -59 38 -73 46 -29 15 -88 60 -168 131 -29 25 -68
56 -88 70 -58 40 -96 79 -169 170 -37 47 -78 96 -90 110 -52 56 -106 126 -116
150 -6 14 -27 45 -45 70 -19 25 -35 50 -35 56 -1 5 -12 25 -26 44 -14 19 -25
41 -25 50 0 9 -8 25 -19 34 -10 9 -21 31 -25 47 -3 17 -15 42 -26 56 -11 14
-20 35 -20 45 0 11 -9 31 -20 45 -11 15 -20 41 -20 61 0 22 -8 44 -20 57 -11
12 -23 42 -26 71 -4 27 -15 66 -25 86 -11 21 -19 58 -19 86 0 26 -5 53 -12 60
-18 18 -517 17 -542 -1 -16 -12 -16 -16 -2 -57 9 -25 16 -70 16 -100 0 -38 6
-64 20 -85 12 -19 20 -47 20 -75 0 -27 8 -58 20 -78 11 -17 23 -51 26 -75 4
-23 13 -50 22 -59 8 -10 18 -38 23 -64 5 -26 18 -56 29 -66 11 -10 20 -31 20
-46 0 -15 9 -35 20 -45 11 -9 20 -27 20 -40 0 -13 9 -32 20 -42 11 -10 20 -28
20 -41 0 -13 11 -35 25 -50 14 -15 25 -37 25 -50 0 -13 9 -29 20 -36 11 -7 20
-18 20 -24 0 -6 9 -19 19 -28 11 -10 22 -29 25 -43 4 -13 25 -47 46 -75 22
-27 40 -54 40 -59 0 -6 10 -20 23 -32 32 -31 102 -118 115 -143 27 -51 212
-239 330 -336 46 -37 86 -68 90 -68 4 0 28 -20 55 -44 26 -25 56 -48 67 -51
11 -4 20 -10 20 -15 0 -4 14 -13 30 -19 17 -5 30 -14 30 -19 0 -10 93 -72 107
-72 6 0 18 -9 28 -20 10 -11 29 -20 42 -20 13 0 33 -11 45 -25 12 -14 29 -25
38 -25 10 0 29 -9 43 -20 14 -11 39 -23 56 -26 16 -4 38 -15 47 -25 9 -11 29
-19 44 -19 15 0 35 -9 45 -20 10 -11 30 -20 45 -20 15 0 39 -9 53 -20 14 -11
46 -24 71 -29 25 -5 52 -14 61 -21 8 -6 44 -16 80 -20 37 -5 74 -15 83 -23 9
-8 48 -17 86 -20 38 -4 80 -14 94 -23 19 -12 63 -19 171 -25 80 -4 168 -15
195 -24 77 -24 358 -21 455 5 52 14 107 20 180 20 84 0 114 4 141 19 19 10 69
21 110 25 42 4 82 14 91 22 9 8 42 19 74 24 32 5 68 16 81 25 13 8 36 15 52
15 16 0 50 11 76 25 26 14 57 25 70 25 13 0 31 9 41 20 10 11 28 20 40 20 11
0 36 11 55 25 19 14 44 25 55 25 12 0 30 9 40 20 10 11 28 20 40 20 13 0 30
11 39 25 10 16 26 25 41 25 14 0 33 9 42 20 10 11 23 20 29 20 6 0 24 10 39
21 19 16 46 23 97 27 61 4 73 2 100 -18 22 -16 52 -25 97 -29 36 -4 77 -14 91
-23 14 -8 50 -18 81 -21 30 -3 71 -15 90 -26 21 -13 51 -21 79 -21 27 0 58 -8
78 -20 21 -13 51 -20 85 -20 37 -1 63 -8 92 -25 28 -18 54 -25 89 -25 34 0 56
-6 70 -19 15 -13 43 -20 99 -25 l77 -6 0 71 c0 56 -4 76 -20 96 -14 18 -20 41
-20 78 0 37 -6 60 -20 78 -13 16 -22 47 -26 87 -3 35 -14 79 -25 99 -11 20
-19 55 -19 81 0 28 -8 56 -20 75 -12 19 -20 47 -20 75 0 29 -9 62 -25 92 -15
29 -25 63 -25 89 0 23 -8 56 -19 73 -10 17 -21 59 -25 94 -3 35 -11 72 -16 83
-9 16 -4 29 25 73 19 29 35 60 35 68 0 8 9 23 20 33 11 10 20 27 20 37 0 10
11 29 25 42 16 15 25 34 25 53 0 17 8 38 19 47 10 9 21 34 25 55 3 21 12 44
21 51 8 6 17 28 21 49 4 20 15 50 25 66 10 17 21 51 25 75 4 25 15 56 26 69
11 15 18 40 18 70 0 27 8 62 20 84 14 26 20 56 20 100 0 46 6 73 23 102 22 40
22 41 22 510 0 444 -1 472 -19 498 -12 18 -20 52 -24 100 -3 41 -13 88 -23
109 -11 21 -19 54 -19 75 0 21 -11 61 -25 88 -13 28 -25 63 -25 79 0 16 -7 37
-16 47 -9 10 -19 38 -21 63 -4 30 -14 52 -29 67 -14 13 -24 34 -24 49 0 15 -9
35 -20 45 -11 10 -20 31 -20 46 0 18 -7 32 -20 39 -11 6 -20 21 -20 33 0 13
-11 38 -25 57 -14 19 -25 41 -25 50 0 9 -9 25 -20 35 -11 10 -20 23 -20 29 0
5 -11 26 -23 45 -13 20 -40 61 -59 91 -20 30 -51 72 -69 92 -45 50 -65 75 -97
125 -15 23 -93 108 -173 190 -174 176 -208 208 -273 253 -27 19 -63 48 -80 63
-17 16 -44 35 -60 44 -16 8 -52 31 -79 50 -27 19 -62 39 -77 45 -16 5 -31 16
-34 24 -3 8 -12 14 -19 14 -8 0 -22 9 -32 20 -10 11 -27 20 -37 20 -10 0 -29
11 -42 25 -14 15 -34 25 -50 25 -14 0 -34 9 -43 20 -10 11 -27 20 -39 20 -13
0 -36 11 -52 25 -17 14 -42 25 -57 25 -15 0 -42 9 -59 19 -17 10 -47 22 -66
26 -19 4 -42 14 -52 22 -9 8 -40 18 -70 22 -29 4 -64 14 -78 23 -14 9 -48 18
-77 21 -29 3 -66 13 -84 23 -49 25 -141 31 -162 9z"/>
<path d="M12050 14181 c-19 -10 -53 -21 -76 -25 -49 -9 -154 -98 -154 -132 0
-11 -11 -31 -25 -44 -24 -22 -25 -29 -25 -135 0 -103 2 -114 25 -145 14 -19
25 -39 25 -44 1 -17 62 -80 100 -103 19 -12 53 -25 74 -29 22 -4 51 -13 65
-20 36 -19 60 -17 106 6 22 11 51 20 66 20 15 0 33 7 40 17 8 9 28 22 46 29
24 11 43 35 82 104 48 84 51 94 51 161 0 75 -8 98 -76 204 -34 53 -116 115
-153 115 -12 0 -38 9 -58 20 -47 25 -69 25 -113 1z"/>
<path d="M13222 14180 c-18 -11 -43 -20 -56 -20 -14 0 -37 -11 -53 -24 -15
-13 -37 -26 -49 -30 -22 -7 -77 -79 -89 -117 -4 -13 -16 -40 -26 -59 -25 -46
-25 -125 0 -173 10 -20 21 -47 25 -60 13 -53 133 -167 176 -167 12 0 38 -9 60
-20 46 -23 87 -26 115 -6 11 7 41 16 66 20 48 7 68 19 128 76 70 66 79 91 84
230 4 123 -1 158 -26 185 -6 8 -25 31 -41 52 -37 48 -90 83 -136 91 -19 3 -51
13 -70 24 -44 23 -67 23 -108 -2z"/>
<path d="M14395 14181 c-22 -10 -56 -22 -76 -25 -25 -5 -53 -23 -93 -60 -31
-30 -56 -57 -56 -61 0 -4 -9 -18 -20 -32 -18 -23 -20 -41 -20 -164 l0 -138 49
-60 c49 -62 110 -111 136 -111 8 0 43 -10 77 -21 l61 -22 71 22 c39 12 76 25
82 29 54 34 94 64 94 71 0 5 14 22 30 38 17 15 30 37 30 48 0 11 9 33 20 50
29 44 35 102 15 155 -35 93 -41 106 -69 143 -42 54 -124 117 -154 117 -14 0
-33 7 -41 15 -29 25 -91 28 -136 6z"/>
<path d="M8700 10680 l0 -750 145 0 145 0 0 435 c0 239 3 435 8 434 4 0 99
-104 212 -230 113 -127 212 -237 221 -245 14 -13 38 10 234 228 120 134 222
244 226 246 5 2 10 -191 11 -430 l3 -433 143 -3 142 -3 0 751 0 750 -48 0 -47
0 -300 -341 c-165 -187 -311 -353 -325 -370 l-25 -30 -325 371 -325 370 -47 0
-48 0 0 -750z"/>
<path d="M14640 10680 l0 -750 135 0 135 0 0 750 0 750 -135 0 -135 0 0 -750z"/>
<path d="M17252 10683 l3 -748 135 0 135 0 3 38 c2 21 6 37 10 35 4 -2 32 -16
62 -32 46 -25 70 -30 146 -34 153 -7 263 36 364 145 245 261 153 687 -175 813
-48 19 -80 24 -165 24 -109 1 -109 1 -232 -62 -5 -2 -8 125 -8 282 l0 286
-140 0 -140 0 2 -747z m625 -14 c125 -61 170 -234 96 -378 -70 -136 -263 -171
-380 -68 -53 46 -63 80 -63 205 0 96 3 114 26 160 55 110 190 144 321 81z"/>
<path d="M15500 10929 c-59 -12 -136 -50 -186 -93 -60 -51 -87 -121 -87 -221
1 -178 84 -254 326 -296 62 -11 122 -26 134 -34 32 -22 38 -53 16 -84 -52 -69
-218 -67 -323 4 l-49 33 -72 -79 c-40 -44 -69 -85 -66 -92 7 -20 104 -82 168
-109 48 -20 71 -22 199 -23 144 0 146 0 211 32 36 18 81 49 101 69 59 59 73
96 73 199 0 76 -4 99 -26 146 -43 92 -107 130 -277 165 -63 13 -128 32 -146
42 -41 24 -45 60 -11 92 52 49 219 36 270 -20 10 -11 21 -20 25 -20 4 0 40 36
80 81 l73 80 -29 30 c-78 81 -262 126 -404 98z"/>
<path d="M10755 10916 c-101 -20 -196 -56 -254 -95 -12 -7 -5 -23 33 -85 26
-41 51 -78 55 -81 5 -3 28 4 52 14 24 11 83 25 131 31 160 21 258 -20 258
-108 l0 -40 -62 26 c-54 23 -78 27 -168 27 -118 0 -182 -17 -249 -66 -88 -63
-150 -207 -137 -318 14 -116 74 -199 180 -248 56 -25 71 -28 181 -28 124 0
131 2 247 63 4 2 8 -14 10 -35 l3 -38 138 -3 137 -3 0 328 c0 358 -8 429 -55
508 -34 58 -97 110 -164 136 -71 28 -235 35 -336 15z m205 -508 c55 -21 70
-46 70 -118 0 -56 -3 -70 -22 -87 -44 -39 -71 -47 -158 -47 -68 0 -93 4 -127
22 -67 35 -90 100 -59 161 38 73 190 109 296 69z"/>
<path d="M11645 10914 c-60 -7 -111 -13 -113 -13 -1 -1 -1 -218 0 -483 l3
-483 135 0 135 0 5 317 5 316 30 44 c45 63 97 90 161 84 29 -2 66 -12 83 -20
17 -9 31 -14 31 -10 0 4 9 60 20 126 11 65 18 124 14 130 -4 7 -37 9 -93 6
-92 -6 -179 -37 -216 -78 -28 -31 -35 -24 -35 30 0 58 13 56 -165 34z"/>
<path d="M12280 10923 c1 -4 89 -228 198 -498 l198 -490 115 -3 116 -3 201
498 200 498 -136 3 c-83 2 -138 -1 -142 -7 -3 -6 -57 -143 -119 -306 -63 -162
-117 -295 -121 -295 -4 0 -59 136 -122 303 l-114 302 -137 3 c-75 1 -137 -1
-137 -5z"/>
<path d="M13806 10914 c-163 -39 -299 -171 -340 -329 -21 -79 -21 -235 0 -306
25 -82 51 -127 113 -192 104 -109 202 -148 373 -148 170 0 283 34 361 109 l37
36 -76 77 -77 77 -57 -29 c-147 -74 -296 -62 -372 33 -16 19 -30 48 -31 64
l-2 29 343 3 342 2 0 78 c-1 316 -191 515 -489 511 -36 0 -92 -7 -125 -15z
m192 -215 c63 -13 128 -66 141 -116 6 -20 8 -40 5 -45 -3 -4 -95 -8 -205 -8
-195 0 -199 0 -199 21 0 60 68 132 142 149 47 11 61 11 116 -1z"/>
<path d="M16120 10623 c0 -170 4 -334 10 -366 20 -126 96 -234 199 -284 51
-26 66 -28 171 -28 114 0 116 0 182 37 36 20 69 34 72 31 3 -4 6 -24 6 -45 l0
-39 133 3 132 3 3 498 2 497 -135 0 -134 0 -3 -323 -3 -324 -29 -37 c-15 -21
-42 -46 -60 -57 -63 -38 -181 -13 -223 47 -40 57 -43 87 -43 395 l0 299 -140
0 -140 0 0 -307z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
